import { match } from "path-to-regexp";
import { corpInstalledbaseSearch } from "@telia/b2b-rest-client";
import { logError } from "@telia/b2x-logging";
import { navigateToUrl } from "single-spa";

import * as analyticsService from "./analytics-service";
export const analytics = analyticsService;

// holds the value managed by getSearchText/setSearchText
let searchText: string | null = null;

/**
 * Sets a search text to be retrieved later by getSearchText
 * @param newSearchText
 */
function setSearchText(newSearchText: string): void {
  searchText = newSearchText;
}

/**
 * @returns the search text that was set when navigateToSearchPage was previously used
 */
export function getSearchText(): string | undefined {
  if (searchText !== null) {
    // the value has been saved with setSearchText
    const returnValue = searchText;

    // clear the saved query
    searchText = null;

    return returnValue;
  } else {
    // no search text found (completely normal)
    return undefined;
  }
}

/**
 * Navigate to search results page, passing the search text. The receving page must be in the same single-spa and call getSearchText to retrieve the search text.
 */
export function navigateToSearchPage(searchText: string): void {
  setSearchText(searchText);
  navigateToUrl(getSearchPageUrl());
}

/**
 * Expects the user to be on any page under "/foretag/mybusiness/${scopeId}/"
 * @returns the URL of the B2B search page.
 */
export function getSearchPageUrl(): string {
  const matcher = match("/foretag/mybusiness/:scopeId/:more*");
  const urlMatch = matcher(window.location.pathname);

  if (urlMatch) {
    const scopeId = urlMatch.params["scopeId"];
    if (scopeId) {
      return `/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/sok`;
    }
  }

  logError(
    "b2b-search-framework",
    "Current URL format is unexpected, returning default URL instead of one relative to current URL."
  );
  // this URL assumes some magic (multipass?) will redirect user to URL with scopeId
  return "/foretag/mybusiness/hantera/produkter-tjanster/sok";
}

export const getSearchResults = (
  categories: Array<string>,
  scopeId: string,
  text: string
): corpInstalledbaseSearch.CancelablePromise<corpInstalledbaseSearch.SubscriptionListUI> =>
  corpInstalledbaseSearch.CorporateInstalledbaseSearchService.searchSubscriptions(
    scopeId,
    categories,
    text
  );

// single-spa exports
export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  return Promise.resolve();
}

export async function unmount(): Promise<void> {
  return Promise.resolve();
}
