import { corpInstalledbaseSearch } from "@telia/b2b-rest-client";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

// NOTE: some of this tracking is kept unchanged from old TSE search page to avoid disrupting tracking,
// including some duplication, misspelling and quirks

const getTotalItemsString = (totalItems: number | undefined): string => {
  // NOTE: This gets passed through:
  // https://diva.teliacompany.net/bitbucket/projects/DCCOMMON/repos/privacy-filter/browse/src/main/java/com/teliacompany/privacy/filter/filters/NumberFilter.java
  // which filters for example "3-10" and "3 - 10" and "1000"

  if (typeof totalItems === "undefined") {
    return "Unknown";
  } else {
    return totalItems > 10000
      ? "More than ten thousand"
      : totalItems > 5000
      ? "five thousand and one to ten thousand"
      : totalItems > 3000
      ? "three thousand and one to five thousand"
      : totalItems > 2000
      ? "two thousand and one to three thousand"
      : totalItems > 1000
      ? "one thousand and one to two thousand"
      : totalItems > 500
      ? "501 to one thousand"
      : totalItems > 100
      ? "101 to 500"
      : totalItems > 50
      ? "51 to 100"
      : totalItems > 10
      ? "11 to 50"
      : totalItems > 2
      ? "3 to 10"
      : totalItems > 1
      ? "2"
      : totalItems > 0
      ? "1"
      : "0";
  }
};

const getResultsEventAction = (isMajorSearch: boolean): string =>
  isMajorSearch ? "Search results" : "Search results after filtering by category";

const getFormattedCategoryArray = (productCategoryArray: Array<string>): string =>
  productCategoryArray.reduce((acc, category) => acc + category + ";", "");

// SUBMIT A SEARCH / CHANGE A FILTER

const submitAnySearch = async (searchFieldLength: number, pageName: string): Promise<void> => {
  await Promise.allSettled([
    analytics.trackEvent(
      analytics.category.SUBSCRIPTION_SEARCH,
      "Search",
      `Query length: ${searchFieldLength}`
    ),
    analytics.trackEvent(
      analytics.category.SUBSCRIPTION_SEARCH,
      `Search from "${pageName}"`,
      `Query length: ${searchFieldLength}`
    ),
  ]);
};

/**
 * Track user made a valid (not too short) search query
 * @param searchFieldLength Number of characters in search form
 * @param pageName user friendly name of page where search query, e.g. "search page", "dashboard"
 */
export const trackSubmitValidSearch = async (
  searchFieldLength: number,
  pageName: string
): Promise<void> => {
  await submitAnySearch(searchFieldLength, pageName);
};

/**
 * Track user made a too short search query
 * @param searchFieldLength Number of characters in search form
 * @param pageName user friendly name of page where search query, e.g. "search page", "dashboard"
 */
export const trackSubmitTooShortSearch = async (
  searchFieldLength: number,
  pageName: string
): Promise<void> => {
  const promises = [submitAnySearch(searchFieldLength, pageName)];

  if (searchFieldLength <= 0) {
    promises.push(
      analytics.trackEvent(
        analytics.category.SUBSCRIPTION_SEARCH,
        getResultsEventAction(true),
        "No query"
      )
    );
  }

  await Promise.allSettled(promises);
};

/**
 * Track user added or removed a filter from search results
 * @param allSelectedProductCategoriesArray An array of language-neutral product category identifiers
 * @param changedProductCategory The category identifier that was added or removed (if "added", also should exist in allSelectedProductCategoriesArray)
 * @param added true if changedProductCategory was added, false if changedProductCategory was removed
 */
export const trackSubmitCategoryFilters = async (
  allSelectedProductCategoriesArray: Array<string>,
  changedProductCategory: string,
  added: boolean
): Promise<void> => {
  await analytics.trackEvent(
    analytics.category.SUBSCRIPTION_SEARCH,
    "Change category filter",
    `${
      added ? "Added" : "Removed"
    } category ${changedProductCategory}, all selected categories: ${getFormattedCategoryArray(
      allSelectedProductCategoriesArray
    )}`
  );
};

// RESULTS OF A SEARCH

/**
 * Track an unexpected search error
 * @param isMajorSearch true if user entered new search query, false if user just changed filters
 */
export const trackUnexpectedSearchError = async (isMajorSearch: boolean): Promise<void> => {
  await analytics.trackEvent(
    analytics.category.SUBSCRIPTION_SEARCH,
    getResultsEventAction(isMajorSearch),
    "Incorrect response"
  );
};

/**
 * Track that backend says we got too many results
 * @param isMajorSearch true if user entered new search query, false if user just changed filters
 */
export const trackTooManyResults = async (isMajorSearch: boolean): Promise<void> => {
  await analytics.trackEvent(
    analytics.category.SUBSCRIPTION_SEARCH,
    getResultsEventAction(isMajorSearch),
    "Too many items found"
  );
};

const trackSuccessResults = async (
  isMajorSearch: boolean,
  subscriptionCount: number
): Promise<void> => {
  await analytics.trackEvent(
    analytics.category.SUBSCRIPTION_SEARCH,
    getResultsEventAction(isMajorSearch),
    `Total items: ${getTotalItemsString(subscriptionCount)}`
  );
};

/**
 * Track a successful search
 * @param searchResults object as given by backend
 */
export const trackSuccessSearchResults = async (
  searchResults: corpInstalledbaseSearch.SubscriptionListUI
): Promise<void> => {
  const promises = [
    trackSuccessResults(true, searchResults.subscriptions ? searchResults.subscriptions.length : 0),
  ];

  if (searchResults.subscriptions && searchResults.subscriptions.length) {
    promises.push(
      analytics.trackEvent(
        analytics.category.SUBSCRIPTION_SEARCH,
        "First search hit category",
        `${searchResults.subscriptions[0].productCategory}`
      )
    );
  }

  (searchResults.categories || []).forEach((category) => {
    promises.push(
      analytics.trackEvent(
        analytics.category.SUBSCRIPTION_SEARCH,
        "Search hit category",
        `${category.productCategory}`
      )
    );
  });

  await Promise.allSettled(promises);
};

/**
 * Track a successful application of category filters
 * @param searchResults object as given by backend
 * @param productCategoryArray An array of language-neutral product category identifiers
 */
export const trackSuccessCategoryFilterResults = async (subscriptionCount: number): Promise<void> =>
  trackSuccessResults(false, subscriptionCount);

// PAGING

const getPagingLabel = (label) => "[subscriptionsSearch] " + label;

/**
 * Track that user changed size of pages on search results
 * @param totalItems number of items in search results
 * @param pageSize New page size
 */
export const trackChangePageSize = async (totalItems: number, pageSize: number): Promise<void> => {
  await analytics.trackEvent(
    analytics.category.SUBSCRIPTION_SEARCH,
    "Set page limit",
    getPagingLabel("Limit: " + pageSize + ", Filtered items: " + getTotalItemsString(totalItems))
  );
};

/**
 * Track that user changed page on search results
 * @param totalItems number of items in search results
 * @param isFirstPage the new page is the first page
 * @param isLastPage the new page is the last page (note: undefined behaviour if both isFirstPage and isLastPage are both true)
 */
export const trackChangePage = async (
  totalItems: number,
  isFirstPage: boolean,
  isLastPage: boolean
): Promise<void> => {
  await analytics.trackEvent(
    analytics.category.SUBSCRIPTION_SEARCH,
    "Pagniate", // known spelling error, preserving old behavior
    getPagingLabel(
      "Filtered items: " +
        getTotalItemsString(totalItems) +
        ", " +
        (isFirstPage ? "First" : isLastPage ? "Last" : "Intermediate") +
        " page, Sort: null/null"
    )
  );
};

// FOLLOW RESULT

/**
 * Track user clicked a search result
 * @param isFirstResult true if the clicked result was the top result
 * @param categoryName the category of which the clicked result belongs
 * @param productCategoryArray the list of all currently selected categories (may be empty list, meaning all categories)
 */
export const trackClickResult = async (
  isFirstResult: boolean,
  productCategory: string,
  productCategoryArray: Array<string>
): Promise<void> => {
  const promises = [
    analytics.trackEvent(
      analytics.category.SUBSCRIPTION_SEARCH,
      "Clicked search result title",
      `Category: ${productCategory}, First result: ${isFirstResult}`
    ),
  ];

  if (productCategoryArray.length) {
    promises.push(
      analytics.trackEvent(
        analytics.category.SUBSCRIPTION_SEARCH,
        "Clicked search result title with category filters applied",
        `Category: ${productCategory}, First result: ${isFirstResult}, Selected categories: ${getFormattedCategoryArray(
          productCategoryArray
        )}`
      )
    );
  } else {
    promises.push(
      analytics.trackEvent(
        analytics.category.SUBSCRIPTION_SEARCH,
        "Clicked search result title WITHOUT category filters applied",
        `Category: ${productCategory}, First result: ${isFirstResult}`
      )
    );
  }

  await Promise.allSettled(promises);
};
